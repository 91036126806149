import { CandidatePortalAuthFlow } from "src/graphQLTypes";
import { AUTH_FLOW_LC_KEY } from "src/constants/system";

type AuthFlowStorageData = {
  authFlow: CandidatePortalAuthFlow;
  caseId: string;
};

class AuthFlowStorageManager {
  setData(authFlow: CandidatePortalAuthFlow, caseId: string): void {
    sessionStorage.setItem(
      AUTH_FLOW_LC_KEY,
      JSON.stringify({ authFlow: authFlow, caseId })
    );
  }

  removeData(): void {
    sessionStorage.removeItem(AUTH_FLOW_LC_KEY);
  }

  getAuthFlowValue(caseId: string): CandidatePortalAuthFlow | null {
    try {
      const stringData = sessionStorage.getItem(AUTH_FLOW_LC_KEY);
      const data = JSON.parse(stringData || "{}") as AuthFlowStorageData;

      return caseId === data?.caseId ? data.authFlow : null;
    } catch {
      return null;
    }
  }
}

export const authFlowStorageManager = new AuthFlowStorageManager();
