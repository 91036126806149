export const CASE_ID_SEARCH_PARAMS_KEY = "caseId";

export const IS_ADMIN_FLOW_SEARCH_PARAMS_KEY = "isAdminFlow";

export const CASE_DATA_LC_KEY = "case-data";

export const AUTH_FLOW_LC_KEY = "cachedAuthFlow";

export const LOGOUT_FLOW_LC_KEY = "logoutFlow";

export const COOKIES_CONSENT_LC_KEY = "cookiesConsent";

export const IS_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY =
  "isDifficultiesButtonVisible";
