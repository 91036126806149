import React, { FC, useCallback, useMemo, useState } from "react";
import { FilesError } from "@security-watchdog/ui-components";

import {
  UploadFileViewerValidator,
  UploadFileViewerValidatorProps
} from "components/UploadFileViewerValidator/UploadFileViewerValidator";
import { fieldAnyValueFragment_Attachment } from "src/graphQLTypes";
import {
  getFilesViewerError,
  getViewerFiles,
  splitDroppedFilesToValidAndInvalid
} from "components/UploadFileViewerWIthPendingFiles/utils";
import { isUploadFile } from "src/utils/typeGuards";

type UploadFileViewerWIthPendingFilesProps = {
  files: fieldAnyValueFragment_Attachment[];
  onChange: (files: File[]) => void;
  onFileDelete: (fileId: string, fileIdx: number) => void;
} & Omit<UploadFileViewerValidatorProps, "files" | "onChange" | "onFileDelete">;

export const UploadFileViewerWIthPendingFiles: FC<
  UploadFileViewerWIthPendingFilesProps
> = ({ files, multiple, onChange, onFileDelete, ...rest }) => {
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);

  const viewerFiles = useMemo(
    () => getViewerFiles(files, pendingFiles),
    [files, pendingFiles]
  );
  const filesError = useMemo(
    () => getFilesViewerError(viewerFiles),
    [viewerFiles]
  );

  const onChangeHandler = useCallback(
    (
      droppedFiles: File[],
      errors: FilesError[] | undefined,
      pendingFilesToSave?: File[]
    ) => {
      const existingFiles = getViewerFiles(
        files,
        pendingFilesToSave ? pendingFilesToSave : pendingFiles
      );
      const filesResult = multiple
        ? [...existingFiles, ...droppedFiles]
        : droppedFiles;

      if (!errors && (droppedFiles.length || pendingFilesToSave?.length)) {
        setPendingFiles([]);
        return onChange(pendingFilesToSave ? pendingFilesToSave : droppedFiles);
      }

      const { invalidFiles, validFiles } = splitDroppedFilesToValidAndInvalid(
        filesResult,
        errors
      );

      if (multiple && validFiles?.length && !invalidFiles?.length) {
        onChange(validFiles);
      }

      if (invalidFiles?.length) {
        setPendingFiles([...validFiles, ...invalidFiles]);
      }
    },
    [multiple, files, pendingFiles, onChange]
  );

  const onFileDeleteHandler = useCallback(
    (fileId: string, fileIdx: number, filesError: FilesError[] | undefined) => {
      const file = viewerFiles?.find((_, idx) => idx === fileIdx);

      if (isUploadFile(file)) {
        const filteredViewerFiles = viewerFiles?.filter(
          (_, idx) => idx !== fileIdx
        );
        const pendingFiles = filteredViewerFiles.filter(isUploadFile);
        setPendingFiles(pendingFiles);

        return onChangeHandler([], filesError, pendingFiles);
      }

      onFileDelete(fileId, fileIdx);
    },
    [viewerFiles, onFileDelete, onChangeHandler]
  );

  return (
    <UploadFileViewerValidator
      files={viewerFiles}
      multiple={multiple}
      filesError={filesError}
      onChange={onChangeHandler}
      onFileDelete={onFileDeleteHandler}
      {...rest}
    />
  );
};
