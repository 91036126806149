const getParseIntValue = (value: string | undefined): number =>
  parseInt(value || "", 10);

export const appConfig =
  typeof envConfig === "object"
    ? {
        AZURE_APP_CONFIGURATION_CONNECTION_STRING:
          envConfig.AZURE_APP_CONFIGURATION_CONNECTION_STRING,
        SERVICE_ENVIRONMENT: envConfig.SERVICE_ENVIRONMENT,
        SURVICATE_SURVEY_ID: envConfig.SURVICATE_SURVEY_ID,
        SURVICATE_WORKSPACE_KEY: envConfig.SURVICATE_WORKSPACE_KEY,
        inputValidation: {
          FREE_TEXT_PATTERN: envConfig.inputValidation?.FREE_TEXT_PATTERN,
          MULTI_STRING_PATTERN: envConfig.inputValidation?.MULTI_STRING_PATTERN,
          PHONE_NUMBER_PATTERN: envConfig.inputValidation?.PHONE_NUMBER_PATTERN,
          PASSPORT_PATTERN: envConfig.inputValidation?.PASSPORT_PATTERN,
          INSURANCE_VALID_CHARACTERS_PATTERN:
            envConfig.inputValidation?.INSURANCE_VALID_CHARACTERS_PATTERN,
          INSURANCE_VALID_FORMAT_PATTERN:
            envConfig.inputValidation?.INSURANCE_VALID_FORMAT_PATTERN,
          COMPANY_PATTERN: envConfig.inputValidation?.COMPANY_PATTERN,
          EMAIL_VALID_FORMAT_PATTERN:
            envConfig.inputValidation?.EMAIL_VALID_FORMAT_PATTERN,
          EMAIL_VALID_CHARACTERS_PATTERN:
            envConfig.inputValidation?.EMAIL_VALID_CHARACTERS_PATTERN,
          ADDRESS_POSTCODE_PATTERN:
            envConfig.inputValidation?.ADDRESS_POSTCODE_PATTERN,
          ADDRESS_LINE_PATTERN: envConfig.inputValidation?.ADDRESS_LINE_PATTERN,
          ADDRESS_TOWN_PATTERN: envConfig.inputValidation?.ADDRESS_TOWN_PATTERN,
          ADDRESS_COUNTY_PATTERN:
            envConfig.inputValidation?.ADDRESS_COUNTY_PATTERN,
          JOB_TITLE_PATTERN: envConfig.inputValidation?.JOB_TITLE_PATTERN,
          NAME_PATTERN: envConfig.inputValidation?.NAME_PATTERN,
          GRADE_PATTERN: envConfig.inputValidation?.GRADE_PATTERN,
          GRADE_SUBJECT_PATTERN:
            envConfig.inputValidation?.GRADE_SUBJECT_PATTERN,
          INTEGER_PATTERN: envConfig.inputValidation?.INTEGER_PATTERN,
          DESCRIPTION_PATTERN: envConfig.inputValidation?.DESCRIPTION_PATTERN,
          FILE_ACCEPT_TYPES: envConfig.inputValidation?.FILE_ACCEPT_TYPES,
          FILE_MAX_SIZE_IN_BYTES:
            envConfig.inputValidation?.FILE_MAX_SIZE_IN_BYTES,
          NAME_MAX_LENGTH: envConfig.inputValidation?.NAME_MAX_LENGTH,
          PHONE_MIN_LENGTH: envConfig.inputValidation?.PHONE_MIN_LENGTH,
          PHONE_MAX_LENGTH: envConfig.inputValidation?.PHONE_MAX_LENGTH,
          PASSPORT_MAX_LENGTH: envConfig.inputValidation?.PASSPORT_MAX_LENGTH,
          INSURANCE_MAX_LENGTH: envConfig.inputValidation?.INSURANCE_MAX_LENGTH,
          INSURANCE_MIN_LENGTH: envConfig.inputValidation?.INSURANCE_MIN_LENGTH,
          MULTI_STRING_MAX_LENGTH:
            envConfig.inputValidation?.MULTI_STRING_MAX_LENGTH,
          COMPANY_NAME_MAX_LENGTH:
            envConfig.inputValidation?.COMPANY_NAME_MAX_LENGTH,
          ADDRESS_POSTCODE_MIN_LENGTH:
            envConfig.inputValidation?.ADDRESS_POSTCODE_MIN_LENGTH,
          ADDRESS_POSTCODE_MAX_LENGTH:
            envConfig.inputValidation?.ADDRESS_POSTCODE_MAX_LENGTH,
          ADDRESS_LINE_MAX_LENGTH:
            envConfig.inputValidation?.ADDRESS_LINE_MAX_LENGTH,
          ADDRESS_TOWN_MAX_LENGTH:
            envConfig.inputValidation?.ADDRESS_TOWN_MAX_LENGTH,
          ADDRESS_COUNTY_MAX_LENGTH:
            envConfig.inputValidation?.ADDRESS_COUNTY_MAX_LENGTH,
          NUMBER_MAX_VALUE: envConfig.inputValidation?.NUMBER_MAX_VALUE,
          NUMBER_MIN_VALUE: envConfig.inputValidation?.NUMBER_MIN_VALUE,
          NUMBER_MAX_FRACTIONAL:
            envConfig.inputValidation?.NUMBER_MAX_FRACTIONAL,
          GRADE_MAX_LENGTH: envConfig.inputValidation?.GRADE_MAX_LENGTH,
          GRADE_SUBJECT_MAX_LENGTH:
            envConfig.inputValidation?.GRADE_SUBJECT_MAX_LENGTH,
          JOB_TITLE_MAX_LENGTH: envConfig.inputValidation?.JOB_TITLE_MAX_LENGTH,
          FREE_TEXT_MAX_LENGTH: envConfig.inputValidation?.FREE_TEXT_MAX_LENGTH,
          DESCRIPTION_MAX_LENGTH:
            envConfig.inputValidation?.DESCRIPTION_MAX_LENGTH,
          EMAIL_MAX_LENGTH: envConfig.inputValidation?.EMAIL_MAX_LENGTH
        }
      }
    : {
        AZURE_APP_CONFIGURATION_CONNECTION_STRING:
          process.env.AZURE_APP_CONFIGURATION_CONNECTION_STRING || "",
        SERVICE_ENVIRONMENT: process.env.SERVICE_ENVIRONMENT || "",
        SURVICATE_SURVEY_ID: process.env.SURVICATE_SURVEY_ID || "",
        SURVICATE_WORKSPACE_KEY: process.env.SURVICATE_WORKSPACE_KEY || "",
        inputValidation: {
          FREE_TEXT_PATTERN: process.env.FREE_TEXT_PATTERN || "",
          MULTI_STRING_PATTERN: process.env.MULTI_STRING_PATTERN || "",
          PHONE_NUMBER_PATTERN: process.env.PHONE_NUMBER_PATTERN || "",
          PASSPORT_PATTERN: process.env.PASSPORT_PATTERN || "",
          INSURANCE_VALID_CHARACTERS_PATTERN:
            process.env.INSURANCE_VALID_CHARACTERS_PATTERN || "",
          INSURANCE_VALID_FORMAT_PATTERN:
            process.env.INSURANCE_VALID_FORMAT_PATTERN || "",
          COMPANY_PATTERN: process.env.COMPANY_PATTERN || "",
          EMAIL_VALID_FORMAT_PATTERN:
            process.env.EMAIL_VALID_FORMAT_PATTERN || "",
          EMAIL_VALID_CHARACTERS_PATTERN:
            process.env.EMAIL_VALID_CHARACTERS_PATTERN || "",
          ADDRESS_POSTCODE_PATTERN: process.env.ADDRESS_POSTCODE_PATTERN || "",
          ADDRESS_LINE_PATTERN: process.env.ADDRESS_LINE_PATTERN || "",
          ADDRESS_TOWN_PATTERN: process.env.ADDRESS_TOWN_PATTERN || "",
          ADDRESS_COUNTY_PATTERN: process.env.ADDRESS_COUNTY_PATTERN || "",
          JOB_TITLE_PATTERN: process.env.JOB_TITLE_PATTERN || "",
          NAME_PATTERN: process.env.NAME_PATTERN || "",
          GRADE_PATTERN: process.env.GRADE_PATTERN || "",
          GRADE_SUBJECT_PATTERN: process.env.GRADE_SUBJECT_PATTERN || "",
          INTEGER_PATTERN: process.env.INTEGER_PATTERN || "",
          DESCRIPTION_PATTERN: process.env.DESCRIPTION_PATTERN || "",
          FILE_ACCEPT_TYPES: process.env.FILE_ACCEPT_TYPES || "",
          FILE_MAX_SIZE_IN_BYTES: getParseIntValue(
            process.env.FILE_MAX_SIZE_IN_BYTES
          ),
          NAME_MAX_LENGTH: getParseIntValue(process.env.NAME_MAX_LENGTH),
          PHONE_MIN_LENGTH: getParseIntValue(process.env.PHONE_MIN_LENGTH),
          PHONE_MAX_LENGTH: getParseIntValue(process.env.PHONE_MAX_LENGTH),
          PASSPORT_MAX_LENGTH: getParseIntValue(
            process.env.PASSPORT_MAX_LENGTH
          ),
          INSURANCE_MAX_LENGTH: getParseIntValue(
            process.env.INSURANCE_MAX_LENGTH
          ),
          INSURANCE_MIN_LENGTH: getParseIntValue(
            process.env.INSURANCE_MIN_LENGTH
          ),
          MULTI_STRING_MAX_LENGTH: getParseIntValue(
            process.env.MULTI_STRING_MAX_LENGTH
          ),
          COMPANY_NAME_MAX_LENGTH: getParseIntValue(
            process.env.COMPANY_NAME_MAX_LENGTH
          ),
          ADDRESS_POSTCODE_MIN_LENGTH: getParseIntValue(
            process.env.ADDRESS_POSTCODE_MIN_LENGTH
          ),
          ADDRESS_POSTCODE_MAX_LENGTH: getParseIntValue(
            process.env.ADDRESS_POSTCODE_MAX_LENGTH
          ),
          ADDRESS_LINE_MAX_LENGTH: getParseIntValue(
            process.env.ADDRESS_LINE_MAX_LENGTH
          ),
          ADDRESS_TOWN_MAX_LENGTH: getParseIntValue(
            process.env.ADDRESS_TOWN_MAX_LENGTH
          ),
          ADDRESS_COUNTY_MAX_LENGTH: getParseIntValue(
            process.env.ADDRESS_COUNTY_MAX_LENGTH
          ),
          NUMBER_MAX_VALUE: getParseIntValue(process.env.NUMBER_MAX_VALUE),
          NUMBER_MIN_VALUE: getParseIntValue(process.env.NUMBER_MIN_VALUE),
          NUMBER_MAX_FRACTIONAL: getParseIntValue(
            process.env.NUMBER_MAX_FRACTIONAL
          ),
          GRADE_MAX_LENGTH: getParseIntValue(process.env.GRADE_MAX_LENGTH),
          GRADE_SUBJECT_MAX_LENGTH: getParseIntValue(
            process.env.GRADE_SUBJECT_MAX_LENGTH
          ),
          JOB_TITLE_MAX_LENGTH: getParseIntValue(
            process.env.JOB_TITLE_MAX_LENGTH
          ),
          FREE_TEXT_MAX_LENGTH: getParseIntValue(
            process.env.FREE_TEXT_MAX_LENGTH
          ),
          DESCRIPTION_MAX_LENGTH: getParseIntValue(
            process.env.DESCRIPTION_MAX_LENGTH
          ),
          EMAIL_MAX_LENGTH: getParseIntValue(process.env.EMAIL_MAX_LENGTH)
        }
      };
