import { PageHeader } from "components/PageHeader";
import { StatusCard } from "components/StatusCard";
import { Tile } from "components/Tile";
import { caseSubmitRequest } from "modules/case/actions";
import {
  isSubmitDisabledSelector,
  takeCandidateData,
  takeCaseId,
  takeClientName,
  takeCustomSectionsList,
  takeIsCaseSubmitting,
  takeSubmitApplicationModalText
} from "modules/case/selectors";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import {
  BUTTON_TYPE,
  CaseIcon,
  ChatIcon,
  CheckOutsideCircleIcon,
  CheckShieldIcon,
  ChevronIcon,
  DIRECTION,
  EyeIcon,
  FolderIcon,
  MedalIcon,
  PinIcon,
  SMALL_ICON_SIZE,
  theme,
  usePrevious,
  UserIcon
} from "@security-watchdog/sw-ui-kit";
import { ModalSubmitApp } from "components/ModalSubmitApp";
import { Header as CommonHeader } from "components/Header";
import { ContentLayout } from "components/ContentLayout";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { ScreenerAccess } from "containers/ScreenerAccess";
import {
  takeIsAllFormsDisabled,
  takeIsTroubleShootingFinishing,
  takeScreenerAccessId,
  takeScreenerAccessStatus
} from "modules/screenerAccess/selectors";
import { takeIsAdminFlow } from "modules/main/selectors";
import * as screenerAccessActions from "modules/screenerAccess/actions";
import {
  getCase_screeningCase_candidate,
  TroubleshootingRequestStatus
} from "src/graphQLTypes";
import { AuthContext, IAuthContextValueProps } from "src/context/auth";
import { IconName, ISection } from "./types";
import * as s from "./styles";

export const SectionList: React.FC = () => {
  useScrollToTop();

  const navigateTo = useNavigate();

  const clientName: string = useSelector(takeClientName);

  const caseId: string = useSelector(takeCaseId);

  const sectionList: ISection[] = useSelector(takeCustomSectionsList);

  const isSubmitDisabled: boolean = useSelector(isSubmitDisabledSelector);

  const isCaseSubmitting: boolean = useSelector(takeIsCaseSubmitting);

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const candidateData: getCase_screeningCase_candidate | undefined =
    useSelector(takeCandidateData);

  const isAdminFlow: boolean = useSelector(takeIsAdminFlow);

  const requestId: string | null = useSelector(takeScreenerAccessId);

  const screenerAccessStatus: TroubleshootingRequestStatus | null = useSelector(
    takeScreenerAccessStatus
  );

  const prevScreenerAccessStatus =
    usePrevious<TroubleshootingRequestStatus | null>(screenerAccessStatus);

  const submitApplicationModalText: string = useSelector(
    takeSubmitApplicationModalText
  );

  const isTroubleShootingFinishing: boolean = useSelector(
    takeIsTroubleShootingFinishing
  );

  const dispatch: Dispatch = useDispatch();

  const { logout }: IAuthContextValueProps =
    useContext<IAuthContextValueProps>(AuthContext);

  const [isShowModal, setIsShowModal]: [boolean, React.Dispatch<boolean>] =
    useState<boolean>(false);

  const openModal = useCallback(() => setIsShowModal(true), []);

  const closeModal = useCallback(() => setIsShowModal(false), []);

  const handleSectionItemClick = useCallback(
    (pathname: string) => (): void =>
      navigateTo({ pathname, search: window.location.search }),
    [navigateTo]
  );

  const caseSubmit = useCallback(() => {
    dispatch(caseSubmitRequest.started({ input: { caseId } }));
    closeModal();
  }, [caseId, dispatch, closeModal]);

  const handleFinishTroubleShooting = (): void => {
    if (caseId && requestId) {
      dispatch(
        screenerAccessActions.doCaseTroubleshootingRequestUpdate.started({
          input: {
            caseId,
            id: requestId,
            status: TroubleshootingRequestStatus.TROUBLESHOOTING_FINISHED
          }
        })
      );
    }
  };

  const setIconForSection = useCallback((iconName: IconName) => {
    switch (iconName) {
      case IconName.user:
        return <UserIcon />;
      case IconName.target:
        return <PinIcon />;
      case IconName.case:
        return <CaseIcon />;
      case IconName.medal:
        return <MedalIcon />;
      case IconName.shield:
        return <CheckShieldIcon />;
      case IconName.folder:
        return <FolderIcon />;
      case IconName.checkShieldIcon:
        return <CheckOutsideCircleIcon />;
      case IconName.chat:
        return <ChatIcon />;
      default:
        return null;
    }
  }, []);

  useEffect(() => {
    if (
      prevScreenerAccessStatus ===
        TroubleshootingRequestStatus.CANDIDATE_ACCEPTED &&
      screenerAccessStatus ===
        TroubleshootingRequestStatus.TROUBLESHOOTING_FINISHED
    ) {
      logout();
    }
  }, [logout, prevScreenerAccessStatus, screenerAccessStatus]);

  return (
    <>
      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        {candidateData && isAdminFlow && (
          <s.ScreenerInfoBox>
            <EyeIcon size={SMALL_ICON_SIZE} color={theme.colors.stormGray} />
            <span>
              You are reviewing {candidateData.firstName}{" "}
              {candidateData.lastName} application
            </span>
          </s.ScreenerInfoBox>
        )}
        <PageHeader
          title="Welcome"
          description={`Your ${clientName} screening process has started. Please complete this application as soon as possible so that we can get started with your checks.`}
        />
        <s.ContainerListSectionItems role="navigation">
          {sectionList.map((item: ISection) => (
            <Tile key={item.id} onClick={handleSectionItemClick(item.path)}>
              <s.InfoSectionBlock>
                {setIconForSection(item.icon)}
                <s.NameSectionBlock>
                  <s.TitleSection>
                    <s.TitleCard>{item.title}</s.TitleCard>
                    <StatusCard title={item.status} status={item.status} />
                  </s.TitleSection>
                  <s.Description>{item.description}</s.Description>
                </s.NameSectionBlock>
                <s.ArrowIconForMobile>
                  <ChevronIcon direction={DIRECTION.RIGHT} size={14} />
                </s.ArrowIconForMobile>
              </s.InfoSectionBlock>
            </Tile>
          ))}
        </s.ContainerListSectionItems>
        <ModalSubmitApp
          isOpen={isShowModal}
          onClose={closeModal}
          onSubmit={caseSubmit}
          text={submitApplicationModalText}
        />
        {isAdminFlow ? (
          <s.ButtonSubmit
            isLoading={isTroubleShootingFinishing}
            buttonType={BUTTON_TYPE.Primary}
            onClick={handleFinishTroubleShooting}
          >
            End work
          </s.ButtonSubmit>
        ) : (
          <s.ButtonSubmit
            isLoading={isCaseSubmitting}
            buttonType={BUTTON_TYPE.Primary}
            isDisabled={isSubmitDisabled || isAllFormsDisabled}
            onClick={openModal}
          >
            Submit application
          </s.ButtonSubmit>
        )}
      </ContentLayout>
    </>
  );
};

export default SectionList;
