export const getGraphQLError = (message: string): string => {
  if (message.includes("Antivirus check failed.")) {
    return "Antivirus check failed.";
  }

  if (message.includes("503: Service Unavailable")) {
    return `
      We\u2019re sorry – something went wrong with the connection to the database.

      Please try refreshing your browser and if the problem continues, please notify your screening team on the details given in your welcome email.
    `;
  }

  return message;
};
