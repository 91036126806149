import * as yup from "yup";
import {
  PHONE_VALIDATION_ERROR_MESSAGE,
  EMAIL_FORMAT_VALIDATION_ERROR_MESSAGE,
  INSURANCE_LENGTH_VALIDATION_ERROR_MESSAGE,
  INSURANCE_FORMAT_VALIDATION_ERROR_MESSAGE,
  INVALID_CHARACTERS_ERROR_MESSAGE,
  REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE,
  MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE,
  MIN_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE,
  MORE_THAN_FIELD_VALIDATION_ERROR_MESSAGE,
  LESS_THAN_FIELD_VALIDATION_ERROR_MESSAGE
} from "@security-watchdog/ui-form-validation";
import { getUploadSizeString } from "src/utils/getUploadSizeString";
import { appConfig } from "src/config";
import {
  isUploadFileForValidation,
  isUploadFilesForValidation
} from "src/utils/typeGuards";
import {
  validateFilesMaxCount,
  validateFilesSize,
  validateFilesType
} from "src/utils/validateUploadedFiles";

export const {
  MULTI_STRING_PATTERN,
  PHONE_NUMBER_PATTERN,
  NAME_PATTERN,
  FREE_TEXT_PATTERN,
  JOB_TITLE_PATTERN,
  PASSPORT_PATTERN,
  INSURANCE_VALID_CHARACTERS_PATTERN,
  ADDRESS_TOWN_PATTERN,
  ADDRESS_COUNTY_PATTERN,
  INSURANCE_VALID_FORMAT_PATTERN,
  GRADE_SUBJECT_PATTERN,
  GRADE_PATTERN,
  INTEGER_PATTERN,
  DESCRIPTION_PATTERN,
  ADDRESS_LINE_PATTERN,
  ADDRESS_POSTCODE_PATTERN,
  EMAIL_VALID_CHARACTERS_PATTERN,
  EMAIL_VALID_FORMAT_PATTERN,
  COMPANY_PATTERN,
  FILE_ACCEPT_TYPES,
  FILE_MAX_SIZE_IN_BYTES,
  NAME_MAX_LENGTH,
  PHONE_MIN_LENGTH,
  PHONE_MAX_LENGTH,
  PASSPORT_MAX_LENGTH,
  INSURANCE_MAX_LENGTH,
  INSURANCE_MIN_LENGTH,
  FREE_TEXT_MAX_LENGTH,
  MULTI_STRING_MAX_LENGTH,
  NUMBER_MIN_VALUE,
  NUMBER_MAX_VALUE,
  NUMBER_MAX_FRACTIONAL,
  JOB_TITLE_MAX_LENGTH,
  ADDRESS_POSTCODE_MIN_LENGTH,
  ADDRESS_POSTCODE_MAX_LENGTH,
  ADDRESS_LINE_MAX_LENGTH,
  ADDRESS_TOWN_MAX_LENGTH,
  ADDRESS_COUNTY_MAX_LENGTH,
  GRADE_MAX_LENGTH,
  GRADE_SUBJECT_MAX_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  EMAIL_MAX_LENGTH
} = appConfig.inputValidation;

const getRegExpValue = (value: string = ""): RegExp => new RegExp(value);

export const INTEGER_VALIDATION_PATTERN = getRegExpValue(INTEGER_PATTERN);

export const PHONE_NUMBER_VALIDATION_PATTERN =
  getRegExpValue(PHONE_NUMBER_PATTERN);

const trimValue = (value: string) => value.replace(/\s+/g, " ");

export const INTEGER_VALIDATION_RULES = yup
  .number()
  .nullable()
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(
    NUMBER_MIN_VALUE,
    MORE_THAN_FIELD_VALIDATION_ERROR_MESSAGE(NUMBER_MIN_VALUE)
  )
  .max(
    NUMBER_MAX_VALUE,
    LESS_THAN_FIELD_VALIDATION_ERROR_MESSAGE(NUMBER_MAX_VALUE)
  )
  .test("maxFractional", INVALID_CHARACTERS_ERROR_MESSAGE, (value) => {
    const fractionalPart = value?.toString().split(".")[1] ?? "";
    return fractionalPart
      ? fractionalPart.length <= NUMBER_MAX_FRACTIONAL
      : true;
  });

export const EMAIL_VALIDATION_RULES = yup
  .string()
  .trim()
  .matches(getRegExpValue(EMAIL_VALID_FORMAT_PATTERN), {
    message: EMAIL_FORMAT_VALIDATION_ERROR_MESSAGE
  })
  .matches(getRegExpValue(EMAIL_VALID_CHARACTERS_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  })
  .max(
    EMAIL_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(EMAIL_MAX_LENGTH)
  );

export const FREE_TEXT_VALIDATION_RULES = yup
  .string()
  .trim()
  .matches(getRegExpValue(FREE_TEXT_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const JOB_TITLE_VALIDATION_RULES = yup
  .string()
  .trim()
  .matches(getRegExpValue(JOB_TITLE_PATTERN), INVALID_CHARACTERS_ERROR_MESSAGE);

export const MULTI_STRING_VALIDATION_RULES = yup
  .string()
  .trim()
  .max(
    MULTI_STRING_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(MULTI_STRING_MAX_LENGTH)
  )
  .matches(getRegExpValue(MULTI_STRING_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const PASSPORT_VALIDATION_RULES = yup
  .string()
  .matches(getRegExpValue(PASSPORT_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  })
  .max(
    PASSPORT_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(PASSPORT_MAX_LENGTH)
  );

export const COMPANY_NAME_VALIDATION_RULES = yup
  .string()
  .trim()
  .required(REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE)
  .max(
    COMPANY_NAME_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(COMPANY_NAME_MAX_LENGTH)
  )
  .matches(getRegExpValue(COMPANY_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const ADDRESS_POSTCODE_VALIDATION_RULES = yup
  .string()
  .trim()
  .required(REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE)
  .min(
    ADDRESS_POSTCODE_MIN_LENGTH,
    MIN_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(ADDRESS_POSTCODE_MIN_LENGTH)
  )
  .max(
    ADDRESS_POSTCODE_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(ADDRESS_POSTCODE_MAX_LENGTH)
  )
  .matches(getRegExpValue(ADDRESS_POSTCODE_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const ADDRESS_LINE_VALIDATION_RULES = yup
  .string()
  .trim()
  .max(
    ADDRESS_LINE_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(ADDRESS_LINE_MAX_LENGTH)
  )
  .matches(getRegExpValue(ADDRESS_LINE_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const ADDRESS_LINE1_VALIDATION_RULES =
  ADDRESS_LINE_VALIDATION_RULES.required(
    REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE
  );

export const ADDRESS_TOWN_VALIDATION_RULES = yup
  .string()
  .trim()
  .required(REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE)
  .max(
    ADDRESS_TOWN_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(ADDRESS_TOWN_MAX_LENGTH)
  )
  .matches(getRegExpValue(ADDRESS_TOWN_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const ADDRESS_COUNTRY_VALIDATION_RULES = yup
  .string()
  .trim()
  .required(REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE);

export const ADDRESS_COUNTY_VALIDATION_RULES = yup
  .string()
  .trim()
  .max(
    ADDRESS_COUNTY_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(ADDRESS_COUNTY_MAX_LENGTH)
  )
  .matches(getRegExpValue(ADDRESS_COUNTY_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const INSURANCE_VALIDATION_RULES = yup
  .string()
  .trim()
  .matches(getRegExpValue(INSURANCE_VALID_CHARACTERS_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  })
  .min(INSURANCE_MIN_LENGTH, INSURANCE_LENGTH_VALIDATION_ERROR_MESSAGE)
  .max(INSURANCE_MAX_LENGTH, INSURANCE_LENGTH_VALIDATION_ERROR_MESSAGE)
  .matches(getRegExpValue(INSURANCE_VALID_FORMAT_PATTERN), {
    message: INSURANCE_FORMAT_VALIDATION_ERROR_MESSAGE
  });

export const PHONE_VALIDATION_RULES = yup
  .string()
  .min(
    PHONE_MIN_LENGTH,
    MIN_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(PHONE_MIN_LENGTH)
  )
  .max(
    PHONE_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(PHONE_MAX_LENGTH)
  )
  .matches(
    getRegExpValue(PHONE_NUMBER_PATTERN),
    PHONE_VALIDATION_ERROR_MESSAGE
  );

export const NAME_VALIDATION_RULES = yup
  .string()
  .trim()
  .matches(getRegExpValue(NAME_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const GRADE_VALIDATION_RULES = yup
  .string()
  .trim()
  .transform(trimValue)
  .max(
    GRADE_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(GRADE_MAX_LENGTH)
  )
  .matches(getRegExpValue(GRADE_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const GRADE_SUBJECT_VALIDATION_RULES = yup
  .string()
  .trim()
  .transform(trimValue)
  .max(
    GRADE_SUBJECT_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(GRADE_SUBJECT_MAX_LENGTH)
  )
  .matches(getRegExpValue(GRADE_SUBJECT_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const FILE_MAX_SIZE_ERROR_MESSAGE = `The total size of uploaded files should not be more than ${getUploadSizeString()}`;

export const FILE_ACCEPTED_TYPE_ERROR_MESSAGE = `Accepted file types are: ${FILE_ACCEPT_TYPES}`;

export const UPLOAD_DOCUMENTS_MAX_COUNT = 10;

export const FILES_UPLOAD_MAX_COUNT_ERROR_MESSAGE = `The maximum allowed number of attachments is ${UPLOAD_DOCUMENTS_MAX_COUNT}`;

export const DESCRIPTION_VALIDATION_RULES = yup
  .string()
  .trim()
  .max(
    DESCRIPTION_MAX_LENGTH,
    MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(DESCRIPTION_MAX_LENGTH)
  )
  .matches(getRegExpValue(DESCRIPTION_PATTERN), {
    message: INVALID_CHARACTERS_ERROR_MESSAGE
  });

export const FILE_VALIDATION_RULES = yup
  .mixed<File>()
  .test("is-valid-file-size", FILE_MAX_SIZE_ERROR_MESSAGE, (value) =>
    isUploadFileForValidation(value) ? validateFilesSize([value]) : true
  )
  .test("is-valid-file-type", FILE_ACCEPTED_TYPE_ERROR_MESSAGE, (value) =>
    isUploadFileForValidation(value) ? validateFilesType([value]) : true
  );

export const MULTI_FILES_VALIDATION_RULES = yup
  .array()
  .of(FILE_VALIDATION_RULES)
  .test(
    "is-valid-files-count",
    FILES_UPLOAD_MAX_COUNT_ERROR_MESSAGE,
    (value) =>
      isUploadFilesForValidation(value) ? validateFilesMaxCount(value) : true
  );
