import React from "react";
import { useSelector } from "react-redux";
import { MessagesNtc } from "containers/MessagesNtc";
import { AuthProvider } from "containers/AuthProvider";
import { mapState } from "containers/Router/mapState";
import { matchPath, useNavigate, useSearchParams } from "react-router-dom";
import { useMount } from "src/hooks/useMount";
import { ROUTES } from "src/constants";
import { createQueryParams } from "src/utils/createQueryParams";
import { Routes } from "./routes";
import { useChatWidget } from "src/lib/useChatWidget";
import { CASE_ID_SEARCH_PARAMS_KEY } from "src/constants/system";

const Router: React.FC = () => {
  useChatWidget();

  const navigateTo = useNavigate();

  const { notifications } = useSelector(mapState);

  const [searchParams] = useSearchParams({});

  useMount(() => {
    const matchParams = matchPath(
      ROUTES.CUSTOM_CASES_LINK,
      window.location.pathname
    );

    const isUrlFromSMS =
      matchParams &&
      matchParams.params.caseId &&
      Number(matchParams.params.caseId);

    const caseIdFromState = searchParams.get("state") || "";

    const caseId = isUrlFromSMS
      ? encodeURI(
          JSON.stringify({
            id: (matchParams && matchParams.params.caseId) || "",
            type: "Case"
          })
        )
      : searchParams.get(CASE_ID_SEARCH_PARAMS_KEY) ||
        encodeURIComponent(caseIdFromState);

    if ((isUrlFromSMS || caseIdFromState) && caseId) {
      navigateTo({
        pathname: ROUTES.ROOT,
        search: createQueryParams(caseId)
      });
    }
  });

  return (
    <>
      {notifications.length > 0 && <MessagesNtc />}
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </>
  );
};

export default Router;
