import { useEffect } from "react";
import { IExtendedWindow } from "src/types";
import { azureAppConfigVar } from "src/cache";
import { useSelector } from "react-redux";
import { takeIsSurvicateInitialized } from "modules/main/selectors";

export const useSurvicate = () => {
  const isSurvicateInitialized: boolean = useSelector(
    takeIsSurvicateInitialized
  );

  useEffect(() => {
    if (isSurvicateInitialized) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      (window as IExtendedWindow)._sva.showSurvey(
        azureAppConfigVar().SURVICATE_SURVEY_ID
      );
    }
  }, [isSurvicateInitialized]);
};
