import React, { FC } from "react";
import * as s from "./styles";
import { Icon, Tooltip, Divider } from "@security-watchdog/ui-components";
import { CandidateCaseStatus } from "src/graphQLTypes";
import { CANDIDATE_CASE_STATUS_TOOLTIP_TEXT } from "src/constants";
import { CaseStatus } from "containers/SuccessSubmit/components/CandidateChecks/components/CaseStatus";

type Props = {
  candidateName: string;
  clientName?: string;
  caseStatus?: CandidateCaseStatus | null;
};

export const SubHeader: FC<Props> = ({
  candidateName,
  clientName,
  caseStatus
}) => (
  <s.SubHeaderContainer>
    <h1 className="h1">Dashboard</h1>
    <s.SubHeaderInfoBlockContainer>
      <s.SubHeaderCandidateContainer className="notes-default color-text-subdued">
        <div>{candidateName}</div>
        <Divider orientation="vertical" className="margin-x-2" />
        <div>{clientName}</div>
      </s.SubHeaderCandidateContainer>
      {caseStatus && (
        <s.SubHeaderStatusContainer>
          <CaseStatus status={caseStatus} />
          <Tooltip
            renderContent={() => (
              <span className="tips">
                {CANDIDATE_CASE_STATUS_TOOLTIP_TEXT[caseStatus]}
              </span>
            )}
            className="background-color-surface-highlight-default"
          >
            <Icon
              name="info"
              size={16}
              className="color-icon-default margin-left-2"
            />
          </Tooltip>
        </s.SubHeaderStatusContainer>
      )}
    </s.SubHeaderInfoBlockContainer>
  </s.SubHeaderContainer>
);
