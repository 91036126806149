import { CandidatePortalAuthFlow } from "src/graphQLTypes";
import { RedirectRequest } from "@azure/msal-browser";
import { getAuthorityLink } from "./getAuthorityLink";

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const getCandidateAzureLoginRequest = (
  authFlow?: CandidatePortalAuthFlow
): RedirectRequest => ({
  scopes: [],
  authority: getAuthorityLink(
    authFlow || CandidatePortalAuthFlow.AZURE_AD_SIGN_IN_OR_SIGN_UP
  )
});
