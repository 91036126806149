import React, { FC } from "react";
import { Header as CommonHeader } from "components/Header";
import { ScreenerAccess } from "containers/ScreenerAccess";
import { SubHeader } from "containers/SuccessSubmit/components/SubHeader";
import { CandidateChecks } from "./components/CandidateChecks";
import { PageWrapper } from "containers/SuccessSubmit/components/PageWrapper";
import { SuccessfullySubmittedCaseInformation } from "containers/SuccessSubmit/components/SuccessfullySubmittedCaseInformation";
import { CandidateCaseStatus } from "src/graphQLTypes";
import {
  useGetCaseInformation,
  useSurvicate,
  useProductActionAnswerDrawerControls
} from "containers/SuccessSubmit/hooks";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "components/common/Spinner";
import { ProductActionAnswerDetails } from "containers/SuccessSubmit/components/ProductActionAnswerDetails";
import { getCaseStatus } from "containers/SuccessSubmit/utils/getCaseStatus";
import { CASE_ID_SEARCH_PARAMS_KEY } from "src/constants/system";

const SuccessSubmitNewComponent: FC = () => {
  useSurvicate();

  const [searchParams] = useSearchParams({ [CASE_ID_SEARCH_PARAMS_KEY]: "" });

  const { data, isLoading, refetch } = useGetCaseInformation(
    searchParams.get(CASE_ID_SEARCH_PARAMS_KEY)
  );

  const caseStatus = getCaseStatus(data?.status, data?.products);

  const drawerControls = useProductActionAnswerDrawerControls();

  const candidateName = `${data?.candidate.firstName} ${data?.candidate.lastName}`;

  const isCaseCompleted = data?.status === CandidateCaseStatus.INACTIVE;

  return isLoading ? (
    <>
      <CommonHeader />
      <Spinner />
    </>
  ) : (
    <>
      <CommonHeader />

      <ProductActionAnswerDetails
        isOpen={drawerControls.isOpen}
        onClose={drawerControls.onClose}
        product={drawerControls.product}
        refetchCase={refetch}
      />

      {isCaseCompleted ? (
        <PageWrapper>
          <SuccessfullySubmittedCaseInformation
            clientName={data?.client.name}
            candidateName={candidateName}
          />
        </PageWrapper>
      ) : (
        <>
          <SubHeader
            candidateName={candidateName}
            clientName={data?.client.name}
            caseStatus={caseStatus}
          />

          <ScreenerAccess />

          <PageWrapper>
            <CandidateChecks
              onActionClick={drawerControls.onOpen}
              products={data?.products || []}
              caseStatus={caseStatus}
            />
          </PageWrapper>
        </>
      )}
    </>
  );
};

export const SuccessSubmitNew = React.memo(SuccessSubmitNewComponent);
