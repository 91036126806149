import { ApolloProvider } from "@apollo/client";
import Router from "containers/Router";
import { initializeSurvicate } from "modules/main/actions";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { theme } from "@security-watchdog/sw-ui-kit";
import { NotificationsProvider } from "@security-watchdog/ui-components";
import { store } from "src/store";
import { BrowserRouter } from "react-router-dom";
import googleAnalytics, { IUseGA } from "src/lib/googleAnalytics";
import { DisclaimerCookie } from "../DisclaimerCookie";
import { GlobalStyle } from "./styles";
import apolloClient from "src/apolloClient";
import "@security-watchdog/ui-components/dist/css/index.css";

window.addEventListener("SurvicateReady", () => {
  store.dispatch(initializeSurvicate());
});

const App: React.FC = () => {
  const { init }: IUseGA = googleAnalytics();

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <NotificationsProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <GlobalStyle />
              <Router />
            </BrowserRouter>
            <DisclaimerCookie />
          </ThemeProvider>
        </NotificationsProvider>
      </ApolloProvider>
    </Provider>
  );
};

export default App;
