import React, { FC, PropsWithChildren } from "react";
import { BUTTON_TYPE, CloseIcon, theme } from "@security-watchdog/sw-ui-kit";

import * as s from "./styles";

type ModalHeaderProps = PropsWithChildren<{
  title: string;
  subTitle?: string;
  onClose?: () => void;
}>;

export const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  subTitle,
  onClose,
  children
}) => (
  <s.Header $hasChildren={Boolean(children)}>
    <s.HeaderTitleWrapper>
      <div>
        <s.HeaderTitle>{title}</s.HeaderTitle>
        {subTitle && <s.HeaderSubTitle>{subTitle}</s.HeaderSubTitle>}
      </div>
      {onClose && (
        <s.HeaderCloseBtn
          ariaLabel="close"
          buttonType={BUTTON_TYPE.Link}
          icon={<CloseIcon color={theme.colors.lightGray} size={20} />}
          onClick={onClose}
        />
      )}
    </s.HeaderTitleWrapper>
    {children}
  </s.Header>
);

ModalHeader.displayName = "DesignSystemModalHeader";
