import { useCallback, useState } from "react";
import {
  StorageMSFileUploadInput,
  StorageMSFileUploadPayload,
  StorageMSFileUploadStorage
} from "src/types/fileUpload";
import { attachmentFragment } from "src/graphQLTypes";
import { getToken } from "src/msal";
import { useNotifications } from "@security-watchdog/ui-components";
import { NOTIFICATION_ERROR_CONFIG } from "src/constants/notifications";
import { useSelector } from "react-redux";
import { takeCandidateData } from "modules/case/selectors";

type ReturnType = attachmentFragment & {
  activationRequired?: boolean | null;
};

type UseUploadFilesToStorageMSProps = {
  contractId: string;
};

export const uploadFileToStorageMS = async (
  file: File,
  {
    uploadedBy,
    shared,
    container = StorageMSFileUploadStorage.UPLOADS,
    activationRequired,
    candidateEmail,
    contractId
  }: StorageMSFileUploadInput
): Promise<ReturnType> => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("fileName", file.name);
  formData.append("container", container);
  formData.append("uploadedBy", uploadedBy);
  formData.append("candidateEmail", candidateEmail);
  formData.append("contractId", contractId);
  formData.append("shared", shared.toString());
  formData.append("activationRequired", activationRequired.toString());

  const response = await fetch("/storage/rest/v2/files", {
    method: "POST",
    headers: {
      Authorization: await getToken()
    },
    body: formData
  });

  if (!response.ok) {
    const { errors } = await response.json();

    throw new Error(errors[0]?.message || "File upload failed");
  }

  const uploadedFile = (await response.json()) as StorageMSFileUploadPayload;

  return {
    __typename: "Attachment",
    id: uploadedFile.id.toString(),
    activationRequired: true,
    downloadUrl: uploadedFile.downloadUrl,
    fileName: uploadedFile.fileName,
    uploadDate: uploadedFile.uploadedWhen,
    uploadedBy: uploadedFile.uploadedBy
  };
};

export const useUploadFilesToStorageMS = ({
  contractId
}: UseUploadFilesToStorageMSProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const candidate = useSelector(takeCandidateData);

  const { addNotification } = useNotifications();

  const uploadFile = useCallback(
    async (file: File): Promise<attachmentFragment | undefined> => {
      setIsLoading(true);

      try {
        const uploadedFile = await uploadFileToStorageMS(file, {
          uploadedBy: `${candidate?.firstName} ${candidate?.lastName}`,
          candidateEmail: candidate?.emailAddress || "",
          shared: false,
          activationRequired: true,
          contractId
        });

        setIsLoading(false);

        return uploadedFile;
      } catch (error) {
        setIsLoading(false);

        if (error instanceof Error) {
          addNotification({
            ...NOTIFICATION_ERROR_CONFIG,
            content: error.message || "Something went wrong"
          });
        }
      }
    },
    [addNotification]
  );

  return { isLoading, uploadFile };
};
