import { azureAppConfigVar } from "src/cache";

export const isTrustIdProductEnabled = (): boolean =>
  Boolean(
    azureAppConfigVar().featureToggle?.["ENABLE_TRUST_ID_INTEGRATED_PRODUCT"]
  );

export const isChatBotLiveChatEnabled = (): boolean =>
  Boolean(azureAppConfigVar().featureToggle?.["ENABLE_CHAT_BOT_LIVE_CHAT"]);

export const isConditionalProductsSubmitEnabled = (): boolean =>
  Boolean(
    azureAppConfigVar().featureToggle?.["ENABLE_CONDITIONAL_PRODUCTS_SUBMIT"]
  );

export const isKonfirIntegrationEnabled = (): boolean =>
  Boolean(azureAppConfigVar().featureToggle?.["ENABLE_KONFIR_INTEGRATION"]);
