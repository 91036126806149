import { ApolloClient, ApolloLink } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { TYPE_NOTIFICATION } from "@security-watchdog/sw-ui-kit";
import * as actionsMsgNtc from "modules/messages/actions";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { store } from "./store";
import { getGraphQLError } from "./utils";
import { cache, sessionIsTerminatedError } from "./cache";
import { getSessionAuthToken, getToken } from "./msal";
import { DefaultContext } from "@apollo/client/core";

const GRAPHQL_PUBLIC_REQUEST_OPERATION_NAME = ["GetAuthFlow"];

const apolloClient = new ApolloClient({
  name: "sw-candidate-portal",
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  link: ApolloLink.from([
    onError(
      ({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            // eslint-disable-next-line @typescript-eslint/typedef
            const { message, locations, path, extensions } = err;

            if (extensions?.code === "sessionIsTerminated") {
              sessionIsTerminatedError(extensions.code as string);

              return;
            }

            if (extensions?.code === "UNAUTHENTICATED") {
              let token = "";

              (async () => {
                token = await getToken();
              })();

              const oldHeaders: Record<string, unknown> = operation.getContext()
                .headers as Record<string, unknown>;

              const newHeaders = {
                ...oldHeaders,
                authorization: token,
                "x-refresh-user-ms-token": true
              };

              operation.setContext({
                headers: newHeaders
              });

              return forward(operation);
            } else {
              // hide TrustID Guest Link error here, because we shown more user friendly error on client side
              if (!message?.includes("Failed to create TrustID Guest Link")) {
                store.dispatch(
                  actionsMsgNtc.addMessageNtc({
                    id: uuidv4(),
                    type: TYPE_NOTIFICATION.Error,
                    message: getGraphQLError(message)
                  })
                );
              }
            }
            // eslint-disable-next-line no-console
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
        }
        if (networkError) {
          // eslint-disable-next-line no-console
          console.log(`[Network error]: ${networkError}`);
        }
      }
    ),
    setContext(async (_request, { headers }: DefaultContext) => {
      return {
        headers: {
          ...headers,
          authorization: GRAPHQL_PUBLIC_REQUEST_OPERATION_NAME.includes(
            _request.operationName || ""
          )
            ? undefined
            : await getSessionAuthToken()
        }
      };
    }),
    createUploadLink({
      uri: "/api/graphql",
      headers: {
        "apollo-require-preflight": true
      }
    })
  ]),
  cache,
  assumeImmutableResults: true,
  defaultOptions: {
    // Configuration to disable Apollo cache
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "none"
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "none"
    },
    mutate: {
      errorPolicy: "none"
    }
  }
});

export default apolloClient;
