import React, {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo
} from "react";
import {
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType
} from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useIsAuthenticated,
  useMsal
} from "@azure/msal-react";
import { AuthContextProvider, IAuthContextValueProps } from "src/context/auth";
import {
  getMsalInstance,
  getScreenerAzureB2CConfig,
  getScreenerAzureConfig
} from "src/config";
import { azureAppConfigVar } from "src/cache";
import { AzureErrors } from "components/AzureErrors";
import { Spinner } from "components/common/Spinner";
import { LOGOUT_FLOW_LC_KEY } from "src/constants/system";
import { logoutFromBackEnd } from "src/utils/logout";
import { authFlowStorageManager } from "src/utils";

const AzureProviderManager: FC<PropsWithChildren> = ({ children }) => {
  const { inProgress, instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        authFlowStorageManager.removeData();
      }

      if (
        event.eventType === EventType.LOGIN_START &&
        localStorage.getItem(LOGOUT_FLOW_LC_KEY)
      ) {
        authFlowStorageManager.removeData();
        localStorage.removeItem(LOGOUT_FLOW_LC_KEY);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  const logout = useCallback(async () => {
    localStorage.setItem(LOGOUT_FLOW_LC_KEY, "true");
    await logoutFromBackEnd();
    await instance.logout();
  }, [instance]);

  const value = useMemo<IAuthContextValueProps>(
    () => ({
      isAuthenticated,
      logout,
      isLoading: inProgress !== InteractionStatus.None
    }),
    [inProgress, isAuthenticated, logout]
  );

  return (
    <MsalAuthenticationTemplate
      loadingComponent={Spinner}
      errorComponent={AzureErrors}
      interactionType={InteractionType.Redirect}
    >
      <AuthContextProvider value={value}>{children}</AuthContextProvider>
    </MsalAuthenticationTemplate>
  );
};

const ScreenerAzureProviderComponent: FC<PropsWithChildren<unknown>> = ({
  children
}: PropsWithChildren<unknown>) => {
  const {
    AZURE_SCREENER_TENANT_ID,
    AZURE_SCREENER_CLIENT_ID,
    AZURE_SCREENER_B2C_CLIENT_ID,
    AZURE_SCREENER_B2C_AUTHORITY_DOMAIN,
    AZURE_SCREENER_B2C_SIGN_IN_NAME,
    featureToggle
  } = azureAppConfigVar();

  const pca = getMsalInstance(
    featureToggle.ENABLE_SCREENER_AZURE_B2C
      ? getScreenerAzureB2CConfig({
          AUTHORITY_DOMAIN: AZURE_SCREENER_B2C_AUTHORITY_DOMAIN,
          CLIENT_ID: AZURE_SCREENER_B2C_CLIENT_ID,
          SIGN_IN_NAME: AZURE_SCREENER_B2C_SIGN_IN_NAME
        })
      : getScreenerAzureConfig({
          AZURE_SCREENER_TENANT_ID,
          AZURE_SCREENER_CLIENT_ID
        })
  );

  return (
    <MsalProvider instance={pca}>
      <AzureProviderManager>{children}</AzureProviderManager>
    </MsalProvider>
  );
};

export const ScreenerAzureProvider = memo<PropsWithChildren<unknown>>(
  ScreenerAzureProviderComponent
);
