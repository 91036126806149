import "./polyfills";
import App from "components/App";
import React from "react";
import "src/lib/survicate";
import { azureAppConfigVar } from "./cache";
import { azureAppConfigInstance } from "config/azureAppConfig";
import { createRoot } from "react-dom/client";

async function main() {
  azureAppConfigVar(await azureAppConfigInstance.get());

  const container = window.document.getElementById("root") as HTMLElement;

  const root = createRoot(container);

  root.render(<App />);
}

try {
  void main();
} catch (err) {
  // eslint-disable-next-line no-console
  console.error("Error in main function:", err);
}
