import { useQuery } from "@apollo/client";
import { GET_AUTH_FLOW } from "containers/AuthProvider/api";
import {
  CandidatePortalAuthFlow,
  GetAuthFlow,
  GetAuthFlowVariables
} from "src/graphQLTypes";
import { useEffect, useMemo, useState } from "react";
import { authFlowStorageManager } from "src/utils";

export const useGetAuthFlow = (caseId: string, skip?: boolean) => {
  const localStorageAuthFlowValue = useMemo(
    () => authFlowStorageManager.getAuthFlowValue(caseId),
    [caseId]
  );

  const [cachedAuthFlow] = useState<CandidatePortalAuthFlow | null>(
    localStorageAuthFlowValue
  );

  const { loading: isLoading, data } = useQuery<
    GetAuthFlow,
    GetAuthFlowVariables
  >(GET_AUTH_FLOW, {
    skip: Boolean(cachedAuthFlow || skip),
    variables: {
      caseId
    }
  });

  useEffect(() => {
    if (data?.authFlow.authFlow) {
      authFlowStorageManager.setData(data.authFlow.authFlow, caseId);
    }
  }, [data?.authFlow.authFlow, caseId]);

  return {
    isLoading,
    authFlow: !caseId
      ? CandidatePortalAuthFlow.AZURE_AD_SIGN_UP
      : cachedAuthFlow || data?.authFlow.authFlow
  };
};
