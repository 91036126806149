import React, { FC, memo } from "react";
import * as s from "./styles";
import { Icon } from "@security-watchdog/ui-components";
import { useSelector } from "react-redux";
import { takeHelpInfo } from "modules/case/selectors";

type Props = {
  candidateName: string;
  clientName: string;
};

const Component: FC<Props> = ({ candidateName, clientName }) => {
  const supportInfo = useSelector(takeHelpInfo);
  const supportPhone = supportInfo?.supportPhoneNumber || "+44 (0)1420 593830";

  return (
    <s.Container>
      <s.Wrapper>
        <Icon name={"check-v2"} className="color-icon-success" size={24} />

        <h1 className="h1 color-text-on-surface margin-t-4">
          Your screening has finished
        </h1>
        <p className="body-wide-line color-text-subdued margin-t-1">
          Your checks have been completed and the results shared with our
          client.
        </p>

        <s.CandidateInfoBlockContainer className="margin-t-6">
          <s.CandidateInfoBlockContainerColumn className="body-highlight">
            <span>Candidate</span>
            <span>Client</span>
          </s.CandidateInfoBlockContainerColumn>
          <s.CandidateInfoBlockContainerColumn className="body-wide-line">
            <span>{candidateName || "-"}</span>
            <span>{clientName || "-"}</span>
          </s.CandidateInfoBlockContainerColumn>
        </s.CandidateInfoBlockContainer>

        <s.ContactsWrapper className="notes-wide-line color-text-subdued margin-t-6">
          <div>Contact us for any further details:</div>
          <div>
            <span className="margin-r-1">Email</span>
            <a
              href="mailto:hello@securitywatchdog.org.uk"
              className="notes-default color-text-on-surface"
            >
              hello@securitywatchdog.org.uk
            </a>
          </div>
          <div>
            <span className="margin-r-1">
              <s.ContactsDivider>/</s.ContactsDivider> Support
            </span>
            <a
              href={`tel:${supportPhone}`}
              className="notes-default color-text-on-surface"
            >
              {supportPhone}
            </a>
          </div>
        </s.ContactsWrapper>
      </s.Wrapper>
    </s.Container>
  );
};

Component.displayName = "SuccessfullySubmittedCaseInformation";

export const SuccessfullySubmittedCaseInformation = memo(Component);
