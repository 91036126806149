/* eslint-disable */
import { appConfig } from "src/config";
import { IExtendedWindow } from "src/types";

// We should try to init this after we receive Azure App Configuration
// but before react renders anything
((): void => {
  const s: HTMLScriptElement = document.createElement("script");
  s.src = `//survey.survicate.com/workspaces/${appConfig.SURVICATE_WORKSPACE_KEY}/web_surveys.js`;
  s.async = true;
  const e: HTMLScriptElement = document.getElementsByTagName("script")[0];
  if (e.parentNode) {
    e.parentNode.insertBefore(s, e);
  }
})();

((opts): void => {
  opts.disableTargeting = true;
})(((window as IExtendedWindow)._sva = (window as IExtendedWindow)._sva || {}));
