import React, { FC, PropsWithChildren, useCallback, useState } from "react";
import { Button, BUTTON_TYPE, Checkbox } from "@security-watchdog/sw-ui-kit";

import { DesignSystemModal } from "components/DesignSystemModal";
import * as s from "./styles";

type TermsAndConditionsModalProps = PropsWithChildren<{
  headerSubTitle?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}>;

export const TermsAndConditionsModal: FC<TermsAndConditionsModalProps> = ({
  isOpen,
  headerSubTitle,
  onClose,
  onConfirm,
  children
}) => {
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false);

  const onCloseHandler = useCallback(() => {
    setAreTermsAccepted(false);
    onClose();
  }, [onClose]);

  const onConfirmHandler = useCallback(() => {
    setAreTermsAccepted(false);
    onConfirm();
  }, [onConfirm]);

  return (
    <DesignSystemModal showModal={isOpen}>
      <DesignSystemModal.Header
        title="Terms & Conditions"
        subTitle={headerSubTitle}
        onClose={onCloseHandler}
      />
      <DesignSystemModal.Body>{children}</DesignSystemModal.Body>
      <DesignSystemModal.Footer>
        <s.FooterActions>
          <Checkbox
            value={areTermsAccepted}
            onChange={() => setAreTermsAccepted((prevState) => !prevState)}
            label="I have read and agree to the Terms and Conditions"
          />
          <s.FooterActionButtons>
            <Button buttonType={BUTTON_TYPE.Secondary} onClick={onCloseHandler}>
              Cancel
            </Button>
            <Button
              buttonType={BUTTON_TYPE.Primary}
              isDisabled={!areTermsAccepted}
              onClick={onConfirmHandler}
            >
              Confirm and Proceed
            </Button>
          </s.FooterActionButtons>
        </s.FooterActions>
      </DesignSystemModal.Footer>
    </DesignSystemModal>
  );
};
