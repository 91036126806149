import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";

export interface IReducer {
  isSurvicateInitialized: boolean;
  isAdminFlow: boolean;
}

export const initialState: IReducer = {
  isSurvicateInitialized: false,
  isAdminFlow: false
};

export const reducers = reducerWithInitialState(initialState)
  .case(
    actions.initializeSurvicate,
    (state: IReducer): IReducer => ({
      ...state,
      isSurvicateInitialized: true
    })
  )
  .case(
    actions.updateAdminFlowState,
    (state: IReducer, payload): IReducer => ({
      ...state,
      isAdminFlow: payload
    })
  );
