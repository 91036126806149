import React, { useCallback, useState } from "react";
import {
  AlertCircleIcon,
  theme,
  Button,
  BUTTON_TYPE
} from "@security-watchdog/sw-ui-kit";

import { TermsAndConditionsModal } from "components/TermsAndConditionsModal";
import * as s from "./styles";

export const KonfirPageContent = () => {
  const [isTermsModalOpened, setIsTermsModalOpened] = useState<boolean>(false);

  const onTermsModalClose = useCallback(() => {
    setIsTermsModalOpened(false);
  }, []);

  const onTermsModalConfirm = useCallback(() => {
    setIsTermsModalOpened(false);
  }, []);

  const goToKonfirHandler = useCallback(() => {
    setIsTermsModalOpened(true);
  }, []);

  return (
    <s.Container>
      <TermsAndConditionsModal
        headerSubTitle="Agree to data processing by Konfir"
        isOpen={isTermsModalOpened}
        onClose={onTermsModalClose}
        onConfirm={onTermsModalConfirm}
      >
        {/*TODO will update this text after business give text */}
        <div className="body-wide-line color-text-default">
          <p className="margin-b-4">
            I also authorise Security Watchdog to share my personal data with
            TrustID Limited for the purpose of verifying my right to work in the
            UK and my identity.
          </p>
          <p className="margin-b-4">
            I understand that Trust ID will process my data in accordance with
            UK Data Protection legislation and that the results will be shared
            with Security Watchdog and my prospective employer (or prospective
            client where I will not directly be employed)
          </p>
          <p className="margin-b-4">
            I confirm that I have read and understood Security Watchdog’s
            Privacy Notice.
          </p>
          <p className="margin-b-4">
            I confirm that I have also read my prospective employer’s Privacy
            Notice, as the Data Controller, and understand my subject rights
            under UK GDPR and understand the legal basis for processing my
            personal information.
          </p>
          <p>
            I confirm that I have read and understood Trust ID’s Privacy Notice,
            and understand how my personal data will be processed during the
            identity verification process.
          </p>
        </div>
      </TermsAndConditionsModal>
      <s.Section>
        <s.Title>About Konfir</s.Title>
        <s.Text>
          Konfir is an innovative platform that revolutionizes the employment
          verification process by providing instant access to secure employment
          and income records. Utilizing integrations with payroll systems, HR
          databases, and Open Banking, Konfir ensures that employment
          verifications are quick, reliable, and fully compliant with GDPR
          standards. This service helps employers streamline their hiring
          processes while maintaining the highest levels of security and data
          privacy.
        </s.Text>
      </s.Section>
      <s.Section>
        <s.Title>How it works</s.Title>
        <s.Text>
          Konfir works by connecting to various data sources, including payroll
          systems and banking information, with the candidates consent. Once a
          verification request is made, the candidate is notified via SMS to
          sync their data. This information is then securely retrieved and
          verified in real-time, allowing employers to access accurate
          employment records instantly. The entire process is designed to
          minimize manual effort, reduce errors, and speed up the onboarding
          process significantly.
        </s.Text>
      </s.Section>
      <s.PageInfoBanner>
        <s.InfoBannerIcon>
          <AlertCircleIcon size={20} color={theme.colors.iconWarning} />
        </s.InfoBannerIcon>
        <div>
          We will redirect you to the Konfir portal. After you fill in all the
          required information, you will automatically return to the Candidate
          portal.
        </div>
      </s.PageInfoBanner>
      <s.ActionsWrapper>
        <Button buttonType={BUTTON_TYPE.Primary} onClick={goToKonfirHandler}>
          Go to Konfir form
        </Button>
      </s.ActionsWrapper>
    </s.Container>
  );
};
