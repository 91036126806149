import React, {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { IAzureAdProviderProps } from "containers/AzureAdProvier/types";
import {
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType
} from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useIsAuthenticated,
  useMsal
} from "@azure/msal-react";
import { CandidatePortalAuthFlow } from "src/graphQLTypes";
import { AuthContextProvider, IAuthContextValueProps } from "src/context/auth";
import { getMsalInstance } from "src/config";
import { azureAppConfigVar } from "src/cache";
import {
  getCandidateAzureConfig,
  getCandidateAzureLoginRequest
} from "src/config";
import { AzureErrors } from "components/AzureErrors";
import { Spinner } from "components/common/Spinner";
import { LOGOUT_FLOW_LC_KEY } from "src/constants/system";
import { logoutFromBackEnd } from "src/utils/logout";
import { authFlowStorageManager } from "src/utils";

export const AzureProviderManager: FC<
  PropsWithChildren<{
    authFlow: CandidatePortalAuthFlow;
  }>
> = ({ children, authFlow }) => {
  const { inProgress, instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        authFlowStorageManager.removeData();
      }

      if (
        event.eventType === EventType.LOGIN_START &&
        localStorage.getItem(LOGOUT_FLOW_LC_KEY)
      ) {
        authFlowStorageManager.removeData();
        localStorage.removeItem(LOGOUT_FLOW_LC_KEY);
      }

      if (
        event.eventType === EventType.ACCOUNT_REMOVED &&
        inProgress === InteractionStatus.None &&
        !instance.getActiveAccount()
      ) {
        location.reload();
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, inProgress]);

  const logout = useCallback(async () => {
    localStorage.setItem(LOGOUT_FLOW_LC_KEY, "true");
    await logoutFromBackEnd();
    await instance.logout();
  }, [instance]);

  const value = useMemo<IAuthContextValueProps>(
    () => ({
      isAuthenticated,
      logout,
      isLoading: inProgress !== InteractionStatus.None
    }),
    [inProgress, isAuthenticated, logout]
  );

  return (
    <MsalAuthenticationTemplate
      loadingComponent={Spinner}
      errorComponent={AzureErrors}
      authenticationRequest={getCandidateAzureLoginRequest(authFlow)}
      interactionType={InteractionType.Redirect}
    >
      <AuthContextProvider value={value}>{children}</AuthContextProvider>
    </MsalAuthenticationTemplate>
  );
};

const AzureProviderComponent: FC<PropsWithChildren<IAzureAdProviderProps>> = ({
  children,
  authFlow
}) => {
  const {
    AZURE_CLIENT_ID,
    AZURE_SIGN_UP_NAME,
    AZURE_SIGN_IN_NAME,
    AZURE_RESET_NAME,
    AZURE_SIGN_UP_OR_SIGN_IN_NAME,
    AZURE_AUTHORITY_DOMAIN
  } = azureAppConfigVar();

  const pca = getMsalInstance(
    getCandidateAzureConfig(
      {
        AZURE_CLIENT_ID,
        AZURE_SIGN_UP_NAME,
        AZURE_SIGN_IN_NAME,
        AZURE_RESET_NAME,
        AZURE_SIGN_UP_OR_SIGN_IN_NAME,
        AZURE_AUTHORITY_DOMAIN
      },
      authFlow
    )
  );

  return (
    <MsalProvider instance={pca}>
      <AzureProviderManager authFlow={authFlow}>
        {children}
      </AzureProviderManager>
    </MsalProvider>
  );
};

export const AzureProvider = memo<PropsWithChildren<IAzureAdProviderProps>>(
  AzureProviderComponent
);
