import axios from "axios";

import { getToken } from "src/msal";

export const logoutFromBackEnd = async () => {
  await axios.post("/api/logout", null, {
    headers: {
      authorization: await getToken()
    },
    responseType: "json",
    baseURL: window.location.origin
  });
};
